body, article {
  box-sizing: border-box;
  background-color: #000;
  margin: 0;
  padding: 0;
  font-family: Verdana, Arial, sans-serif;
}

a {
  color: #7f8d6e;
}

.hide {
  display: none;
}

q:before {
  content: "‘";
}

q:after {
  content: "’";
}

.bg-section {
  width: 100%;
}

.bg-section .content {
  box-sizing: border-box;
  width: 100%;
  margin: 0 auto;
  padding: 0 1rem 1rem;
}

.bg-section.dark {
  background-color: #000;
}

.bg-section.dark .content {
  max-width: 50rem;
  padding-bottom: 0;
}

.bg-section.light {
  background-color: #fff;
}

.bg-section.light .content {
  max-width: 40rem;
}

section {
  box-sizing: content-box;
  padding: 1rem 0;
}

h1 {
  text-align: center;
  color: #fff;
  margin: 3rem 1rem 1rem;
  font-size: 3rem;
  font-weight: 100;
}

.date {
  margin-top: -1.4em;
  font-size: .8rem;
}

.video {
  padding: 56.25% 0 0;
  position: relative;
}

.video iframe {
  box-sizing: content-box;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.secvid {
  margin-bottom: 0;
}

.buttons {
  flex-wrap: wrap;
  justify-content: center;
  padding: .5rem 0;
  display: flex;
}

.button {
  width: -moz-fit-content;
  width: fit-content;
  color: #fff;
  cursor: pointer;
  background-color: #7f8d6e;
  border: none;
  margin: .5em .4em;
  padding: .6em 1.6em;
  font-size: 1.2rem;
  text-decoration: none;
  transition: background-color .3s;
}

.button:hover, .button.active {
  background-color: #515946;
}

.collapsed {
  padding: 0;
  overflow: hidden;
}

.collapsed section {
  padding: 0;
  display: none;
}

.collapsed section:target, .collapsed section.target {
  display: block;
}

.collapsed section.hide {
  display: none !important;
}

.links {
  flex-wrap: wrap;
  justify-content: center;
  display: flex;
}

.links span + span:before {
  content: "|";
  margin: .7em;
}

.modal {
  color: #fff;
  max-width: 30rem;
  background-color: #000;
  margin: 2rem auto .5rem;
  padding: 20px;
}

.modal form {
  flex-direction: column;
  display: flex;
}

.modal h2 {
  margin: 0 auto .5rem;
}

.modal p, .modal div {
  margin: .5rem 0;
}

.quantities {
  border: none;
  grid-template-columns: auto auto;
  padding: 0;
  display: grid;
}

.quantities label {
  justify-self: start;
}

.quantities input {
  justify-self: end;
}

label {
  margin: .5rem 0 .2rem;
  font-weight: bold;
}

input, textarea {
  border: none;
  border-radius: 0;
  margin-bottom: .5rem;
}

fieldset {
  margin: 0;
}

input[type="number"] {
  width: 50px;
  height: max-content;
  margin: auto 0 auto 10px;
  font-size: 16px;
}

input[type="text"], input[type="email"], input[type="tel"], textarea {
  width: 100%;
  box-sizing: border-box;
  padding: .3em;
  font-size: 16px;
}

input[type="text"]:focus, input[type="email"]:focus, input[type="tel"]:focus, textarea:focus {
  outline: none;
}

input[type="submit"] {
  appearance: none;
  margin: 20px auto 0;
  font-size: 1rem;
}

.cf-turnstile {
  max-height: 70px;
  transition: max-height .25s ease-out .3s;
  overflow: hidden;
}

.cf-turnstile.collapse {
  max-height: 0;
}

.gallerij {
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: .5rem;
  display: grid;
}

@media (width <= 30rem) {
  .gallerij {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media (width <= 20rem) {
  .gallerij {
    grid-template-columns: 1fr 1fr;
  }
}

.gallerij img {
  width: 100%;
  height: 100%;
  aspect-ratio: 1;
  object-fit: cover;
}

.gallerijmaxi {
  grid-template-columns: 1fr;
}

.gallerijmaxi img {
  height: initial;
  aspect-ratio: initial;
}

.vidthumb {
  cursor: pointer;
  position: relative;
}

.vidthumb:after, .vidthumb:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
}

.vidthumb:after {
  width: 0;
  height: 0;
  border: 12px solid #0000;
  border-left: 20px solid #fff;
  margin-top: -18px;
  margin-left: -7px;
}

@font-face {
  font-family: lg;
  src: url("lg.f8edcdbb.woff2") format("woff2"), url("lg.253cca28.ttf") format("truetype"), url("lg.f801b1a8.woff") format("woff"), url("lg.c9542252.svg#lg") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.lg-icon {
  speak: never;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  font-family: lg !important;
}

.lg-container {
  font-family: system-ui, -apple-system, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, Liberation Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
}

.lg-next, .lg-prev {
  color: #999;
  cursor: pointer;
  z-index: 1084;
  background-color: #00000073;
  border: none;
  border-radius: 2px;
  outline: none;
  margin-top: -10px;
  padding: 8px 10px 9px;
  font-size: 22px;
  display: block;
  position: absolute;
  top: 50%;
}

.lg-next.disabled, .lg-prev.disabled {
  cursor: default;
  opacity: 0 !important;
}

.lg-next:hover:not(.disabled), .lg-prev:hover:not(.disabled) {
  color: #fff;
}

.lg-single-item .lg-next, .lg-single-item .lg-prev {
  display: none;
}

.lg-next {
  right: 20px;
}

.lg-next:before {
  content: "";
}

.lg-prev {
  left: 20px;
}

.lg-prev:after {
  content: "";
}

@keyframes lg-right-end {
  0% {
    left: 0;
  }

  50% {
    left: -30px;
  }

  100% {
    left: 0;
  }
}

@keyframes lg-left-end {
  0% {
    left: 0;
  }

  50% {
    left: 30px;
  }

  100% {
    left: 0;
  }
}

.lg-outer.lg-right-end .lg-object {
  animation: .3s lg-right-end;
  position: relative;
}

.lg-outer.lg-left-end .lg-object {
  animation: .3s lg-left-end;
  position: relative;
}

.lg-toolbar {
  z-index: 1082;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.lg-media-overlap .lg-toolbar {
  background-image: linear-gradient(0deg, #0000, #0006);
}

.lg-toolbar .lg-icon {
  color: #999;
  cursor: pointer;
  float: right;
  height: 47px;
  text-align: center;
  width: 50px;
  will-change: color;
  -o-transition: color .2s linear;
  box-shadow: none;
  background: none;
  border: none;
  outline: none;
  padding: 10px 0;
  font-size: 24px;
  line-height: 27px;
  transition: color .2s linear;
  text-decoration: none !important;
}

.lg-toolbar .lg-icon.lg-icon-18 {
  font-size: 18px;
}

.lg-toolbar .lg-icon:hover {
  color: #fff;
}

.lg-toolbar .lg-close:after {
  content: "";
}

.lg-toolbar .lg-maximize {
  font-size: 22px;
}

.lg-toolbar .lg-maximize:after {
  content: "";
}

.lg-toolbar .lg-download:after {
  content: "";
}

.lg-sub-html {
  color: #eee;
  text-align: center;
  z-index: 1080;
  opacity: 0;
  -o-transition: opacity .2s ease-out 0s;
  padding: 10px 40px;
  font-size: 16px;
  transition: opacity .2s ease-out;
}

.lg-sub-html h4 {
  margin: 0;
  font-size: 13px;
  font-weight: bold;
}

.lg-sub-html p {
  margin: 5px 0 0;
  font-size: 12px;
}

.lg-sub-html a {
  color: inherit;
}

.lg-sub-html a:hover {
  text-decoration: underline;
}

.lg-media-overlap .lg-sub-html {
  background-image: linear-gradient(#0000, #0009);
}

.lg-item .lg-sub-html {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.lg-error-msg {
  color: #999;
  font-size: 14px;
}

.lg-counter {
  color: #999;
  height: 47px;
  vertical-align: middle;
  padding-top: 12px;
  padding-left: 20px;
  font-size: 16px;
  display: inline-block;
}

.lg-closing .lg-toolbar, .lg-closing .lg-prev, .lg-closing .lg-next, .lg-closing .lg-sub-html {
  opacity: 0;
  -webkit-transition: -webkit-transform .08 cubic-bezier(0, 0, .25, 1) 0s, opacity .08 cubic-bezier(0, 0, .25, 1) 0s, color .08 linear;
  -moz-transition: -moz-transform .08 cubic-bezier(0, 0, .25, 1) 0s, opacity .08 cubic-bezier(0, 0, .25, 1) 0s, color .08 linear;
  -o-transition: -o-transform .08 cubic-bezier(0, 0, .25, 1) 0s, opacity .08 cubic-bezier(0, 0, .25, 1) 0s, color .08 linear;
  transition: transform .08 cubic-bezier(0, 0, .25, 1) 0s, opacity .08 cubic-bezier(0, 0, .25, 1) 0s, color .08 linear;
}

body:not(.lg-from-hash) .lg-outer.lg-start-zoom .lg-item:not(.lg-zoomable) .lg-img-wrap, body:not(.lg-from-hash) .lg-outer.lg-start-zoom .lg-item:not(.lg-zoomable) .lg-video-cont {
  opacity: 0;
  will-change: transform, opacity;
  transform: scale3d(.5, .5, .5);
  -webkit-transition: -webkit-transform .25s cubic-bezier(0, 0, .25, 1), opacity .25s cubic-bezier(0, 0, .25, 1) !important;
  -o-transition: -o-transform .25s cubic-bezier(0, 0, .25, 1) 0s, opacity .25s cubic-bezier(0, 0, .25, 1) !important;
  -moz-transition: -moz-transform .25s cubic-bezier(0, 0, .25, 1), opacity .25s cubic-bezier(0, 0, .25, 1) !important;
  transition: transform .25s cubic-bezier(0, 0, .25, 1), opacity .25s cubic-bezier(0, 0, .25, 1) !important;
}

body:not(.lg-from-hash) .lg-outer.lg-start-zoom .lg-item:not(.lg-zoomable).lg-complete .lg-img-wrap, body:not(.lg-from-hash) .lg-outer.lg-start-zoom .lg-item:not(.lg-zoomable).lg-complete .lg-video-cont {
  opacity: 1;
  transform: scale3d(1, 1, 1);
}

.lg-icon:focus-visible {
  color: #fff;
  border-radius: 3px;
  outline: 1px dashed #fff9;
}

.lg-toolbar .lg-icon:focus-visible {
  outline-offset: -5px;
  border-radius: 8px;
}

.lg-group:after {
  content: "";
  clear: both;
  display: table;
}

.lg-container {
  outline: none;
  display: none;
}

.lg-container.lg-show {
  display: block;
}

.lg-on {
  scroll-behavior: unset;
}

.lg-overlay-open {
  overflow: hidden;
}

.lg-toolbar, .lg-prev, .lg-next, .lg-pager-outer, .lg-hide-sub-html .lg-sub-html {
  opacity: 0;
  will-change: transform, opacity;
  -webkit-transition: -webkit-transform .25s cubic-bezier(0, 0, .25, 1), opacity .25s cubic-bezier(0, 0, .25, 1);
  -o-transition: -o-transform .25s cubic-bezier(0, 0, .25, 1) 0s, opacity .25s cubic-bezier(0, 0, .25, 1) 0s;
  -moz-transition: -moz-transform .25s cubic-bezier(0, 0, .25, 1), opacity .25s cubic-bezier(0, 0, .25, 1);
  transition: transform .25s cubic-bezier(0, 0, .25, 1), opacity .25s cubic-bezier(0, 0, .25, 1);
}

.lg-show-in .lg-toolbar, .lg-show-in .lg-prev, .lg-show-in .lg-next, .lg-show-in .lg-pager-outer, .lg-show-in.lg-hide-sub-html .lg-sub-html {
  opacity: 1;
}

.lg-show-in .lg-hide-items .lg-prev {
  opacity: 0;
  transform: translate3d(-10px, 0, 0);
}

.lg-show-in .lg-hide-items .lg-next {
  opacity: 0;
  transform: translate3d(10px, 0, 0);
}

.lg-show-in .lg-hide-items .lg-toolbar {
  opacity: 0;
  transform: translate3d(0, -10px, 0);
}

.lg-show-in .lg-hide-items.lg-hide-sub-html .lg-sub-html {
  opacity: 0;
  transform: translate3d(0, 20px, 0);
}

.lg-outer {
  width: 100%;
  height: 100%;
  z-index: 1050;
  text-align: left;
  opacity: .001;
  will-change: auto;
  -o-transition: opacity .15s ease 0s;
  outline: none;
  transition: opacity .15s;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

.lg-outer * {
  box-sizing: border-box;
}

.lg-outer.lg-zoom-from-image, .lg-outer.lg-visible {
  opacity: 1;
}

.lg-outer.lg-css3 .lg-item:not(.lg-start-end-progress).lg-prev-slide, .lg-outer.lg-css3 .lg-item:not(.lg-start-end-progress).lg-next-slide, .lg-outer.lg-css3 .lg-item:not(.lg-start-end-progress).lg-current {
  -webkit-transition-duration: inherit !important;
  transition-duration: inherit !important;
  -webkit-transition-timing-function: inherit !important;
  transition-timing-function: inherit !important;
}

.lg-outer.lg-css3.lg-dragging .lg-item.lg-prev-slide, .lg-outer.lg-css3.lg-dragging .lg-item.lg-next-slide, .lg-outer.lg-css3.lg-dragging .lg-item.lg-current {
  opacity: 1;
  transition-duration: 0s !important;
}

.lg-outer.lg-grab img.lg-object {
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: -o-grab;
  cursor: -ms-grab;
  cursor: grab;
}

.lg-outer.lg-grabbing img.lg-object {
  cursor: move;
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: -o-grabbing;
  cursor: -ms-grabbing;
  cursor: grabbing;
}

.lg-outer .lg-content {
  position: absolute;
  inset: 0;
}

.lg-outer .lg-inner {
  width: 100%;
  -o-transition: opacity 0s;
  white-space: nowrap;
  transition: opacity;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}

.lg-outer .lg-item {
  display: none !important;
}

.lg-outer .lg-item:not(.lg-start-end-progress) {
  background: url("loading.9e763859.gif") center no-repeat;
}

.lg-outer.lg-css3 .lg-prev-slide, .lg-outer.lg-css3 .lg-current, .lg-outer.lg-css3 .lg-next-slide, .lg-outer.lg-css .lg-current {
  display: inline-block !important;
}

.lg-outer .lg-item, .lg-outer .lg-img-wrap {
  text-align: center;
  width: 100%;
  height: 100%;
  display: inline-block;
  position: absolute;
}

.lg-outer .lg-item:before, .lg-outer .lg-img-wrap:before {
  content: "";
  height: 100%;
  vertical-align: middle;
  display: inline-block;
}

.lg-outer .lg-img-wrap {
  white-space: nowrap;
  font-size: 0;
  position: absolute;
  inset: 0;
}

.lg-outer .lg-item.lg-complete {
  background-image: none;
}

.lg-outer .lg-item.lg-current {
  z-index: 1060;
}

.lg-outer .lg-object {
  vertical-align: middle;
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  display: inline-block;
  position: relative;
}

.lg-outer .lg-empty-html.lg-sub-html, .lg-outer .lg-empty-html .lg-sub-html {
  display: none;
}

.lg-outer.lg-hide-download .lg-download {
  opacity: .75;
  pointer-events: none;
}

.lg-outer .lg-first-slide .lg-dummy-img {
  position: absolute;
  top: 50%;
  left: 50%;
}

.lg-outer.lg-components-open:not(.lg-zoomed) .lg-components {
  opacity: 1;
  transform: translate3d(0, 0%, 0);
}

.lg-outer.lg-components-open:not(.lg-zoomed) .lg-sub-html {
  opacity: 1;
  transition: opacity .2s ease-out .15s;
}

.lg-backdrop {
  z-index: 1040;
  opacity: 0;
  will-change: auto;
  -o-transition: opacity .333s ease-in 0s;
  background-color: #000;
  transition: opacity .333s ease-in;
  position: fixed;
  inset: 0;
}

.lg-backdrop.in {
  opacity: 1;
}

.lg-css3.lg-no-trans .lg-prev-slide, .lg-css3.lg-no-trans .lg-next-slide, .lg-css3.lg-no-trans .lg-current {
  -o-transition: none 0s ease 0s !important;
  transition: none !important;
}

.lg-css3.lg-use-css3 .lg-item {
  backface-visibility: hidden;
}

.lg-css3.lg-fade .lg-item {
  opacity: 0;
}

.lg-css3.lg-fade .lg-item.lg-current {
  opacity: 1;
}

.lg-css3.lg-fade .lg-item.lg-prev-slide, .lg-css3.lg-fade .lg-item.lg-next-slide, .lg-css3.lg-fade .lg-item.lg-current {
  -o-transition: opacity .1s ease 0s;
  transition: opacity .1s;
}

.lg-css3.lg-use-css3 .lg-item.lg-start-progress {
  -webkit-transition: -webkit-transform 1s cubic-bezier(.175, .885, .32, 1.275);
  -o-transition: -o-transform 1s cubic-bezier(.175, .885, .32, 1.275) 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(.175, .885, .32, 1.275);
  transition: transform 1s cubic-bezier(.175, .885, .32, 1.275);
}

.lg-css3.lg-use-css3 .lg-item.lg-start-end-progress {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, .25, 1);
  -o-transition: -o-transform 1s cubic-bezier(0, 0, .25, 1) 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, .25, 1);
  transition: transform 1s cubic-bezier(0, 0, .25, 1);
}

.lg-css3.lg-slide.lg-use-css3 .lg-item {
  opacity: 0;
}

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-prev-slide {
  transform: translate3d(-100%, 0, 0);
}

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-next-slide {
  transform: translate3d(100%, 0, 0);
}

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-current {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-prev-slide, .lg-css3.lg-slide.lg-use-css3 .lg-item.lg-next-slide, .lg-css3.lg-slide.lg-use-css3 .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, .25, 1), opacity .1s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, .25, 1) 0s, opacity .1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, .25, 1), opacity .1s;
  transition: transform 1s cubic-bezier(0, 0, .25, 1), opacity .1s;
}

.lg-container {
  display: none;
}

.lg-container.lg-show {
  display: block;
}

.lg-container.lg-dragging-vertical .lg-backdrop {
  transition-duration: 0s !important;
}

.lg-container.lg-dragging-vertical .lg-css3 .lg-item.lg-current {
  opacity: 1;
  transition-duration: 0s !important;
}

.lg-inline .lg-backdrop, .lg-inline .lg-outer {
  position: absolute;
}

.lg-inline .lg-backdrop {
  z-index: 1;
}

.lg-inline .lg-outer {
  z-index: 2;
}

.lg-inline .lg-maximize:after {
  content: "";
}

.lg-components {
  will-change: transform;
  -webkit-transition: -webkit-transform .35s ease-out;
  -o-transition: -o-transform .35s ease-out 0s;
  -moz-transition: -moz-transform .35s ease-out;
  z-index: 1080;
  transition: transform .35s ease-out;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  transform: translate3d(0, 100%, 0);
}

.lg-outer .lg-video-cont {
  text-align: center;
  vertical-align: middle;
  display: inline-block;
  position: relative;
}

.lg-outer .lg-video-cont .lg-object {
  width: 100% !important;
  height: 100% !important;
}

.lg-outer .lg-has-iframe .lg-video-cont {
  -webkit-overflow-scrolling: touch;
  overflow: auto;
}

.lg-outer .lg-video-object {
  width: 100%;
  height: 100%;
  z-index: 3;
  position: absolute;
  inset: 0;
}

.lg-outer .lg-video-poster {
  z-index: 1;
}

.lg-outer .lg-has-video .lg-video-object {
  opacity: 0;
  will-change: opacity;
  -o-transition: opacity .3s ease-in;
  transition: opacity .3s ease-in;
}

.lg-outer .lg-has-video.lg-video-loaded .lg-video-poster, .lg-outer .lg-has-video.lg-video-loaded .lg-video-play-button {
  opacity: 0 !important;
}

.lg-outer .lg-has-video.lg-video-loaded .lg-video-object {
  opacity: 1;
}

@keyframes lg-play-stroke {
  0% {
    stroke-dasharray: 1 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 89 200;
    stroke-dashoffset: -35px;
  }

  100% {
    stroke-dasharray: 89 200;
    stroke-dashoffset: -124px;
  }
}

@keyframes lg-play-rotate {
  100% {
    transform: rotate(360deg);
  }
}

.lg-video-play-button {
  width: 18%;
  max-width: 140px;
  z-index: 2;
  cursor: pointer;
  will-change: opacity, transform;
  -webkit-transition: -webkit-transform .25s cubic-bezier(.17, .88, .32, 1.28), opacity .1s;
  -o-transition: -o-transform .25s cubic-bezier(.17, .88, .32, 1.28), opacity .1s;
  -moz-transition: -moz-transform .25s cubic-bezier(.17, .88, .32, 1.28), opacity .1s;
  transition: transform .25s cubic-bezier(.17, .88, .32, 1.28), opacity .1s;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)scale(1);
}

.lg-video-play-button:hover .lg-video-play-icon-bg, .lg-video-play-button:hover .lg-video-play-icon {
  opacity: 1;
}

.lg-video-play-icon-bg {
  fill: none;
  stroke-width: 3%;
  stroke: #fcfcfc;
  opacity: .6;
  will-change: opacity;
  -o-transition: opacity .12s ease-in;
  transition: opacity .12s ease-in;
}

.lg-video-play-icon-circle {
  fill: none;
  stroke-width: 3%;
  stroke: #1e1e1ee6;
  stroke-opacity: 1;
  stroke-linecap: round;
  stroke-dasharray: 200;
  stroke-dashoffset: 200px;
  position: absolute;
  inset: 0;
}

.lg-video-play-icon {
  width: 25%;
  max-width: 120px;
  opacity: .6;
  will-change: opacity;
  -o-transition: opacity .12s ease-in;
  transition: opacity .12s ease-in;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}

.lg-video-play-icon .lg-video-play-icon-inner {
  fill: #fcfcfc;
}

.lg-video-loading .lg-video-play-icon-circle {
  animation: 2s linear .25s infinite lg-play-rotate, 1.5s ease-in-out .25s infinite lg-play-stroke;
}

.lg-video-loaded .lg-video-play-button {
  opacity: 0;
  transform: translate(-50%, -50%)scale(.7);
}

.lg-outer .lg-thumb-outer {
  width: 100%;
  max-height: 350px;
  float: left;
  background-color: #0d0a0a;
  overflow: hidden;
}

.lg-outer .lg-thumb-outer.lg-grab .lg-thumb-item {
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: -o-grab;
  cursor: -ms-grab;
  cursor: grab;
}

.lg-outer .lg-thumb-outer.lg-grabbing .lg-thumb-item {
  cursor: move;
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: -o-grabbing;
  cursor: -ms-grabbing;
  cursor: grabbing;
}

.lg-outer .lg-thumb-outer.lg-dragging .lg-thumb, .lg-outer .lg-thumb-outer.lg-rebuilding-thumbnails .lg-thumb {
  transition-duration: 0s !important;
}

.lg-outer .lg-thumb-outer.lg-thumb-align-middle {
  text-align: center;
}

.lg-outer .lg-thumb-outer.lg-thumb-align-left {
  text-align: left;
}

.lg-outer .lg-thumb-outer.lg-thumb-align-right {
  text-align: right;
}

.lg-outer.lg-single-item .lg-thumb-outer {
  display: none;
}

.lg-outer .lg-thumb {
  height: 100%;
  vertical-align: middle;
  margin-bottom: -5px;
  padding: 5px 0;
  display: inline-block;
}

@media (width >= 768px) {
  .lg-outer .lg-thumb {
    padding: 10px 0;
  }
}

.lg-outer .lg-thumb-item {
  cursor: pointer;
  float: left;
  height: 100%;
  will-change: border-color;
  border-radius: 2px;
  margin-bottom: 5px;
  overflow: hidden;
}

@media (width >= 768px) {
  .lg-outer .lg-thumb-item {
    -o-transition: border-color .25s ease;
    border: 2px solid #fff;
    border-radius: 4px;
    transition: border-color .25s;
  }
}

.lg-outer .lg-thumb-item.active, .lg-outer .lg-thumb-item:hover {
  border-color: #a90707;
}

.lg-outer .lg-thumb-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

.lg-outer.lg-can-toggle .lg-item {
  padding-bottom: 0;
}

.lg-outer .lg-toggle-thumb:after {
  content: "";
}

.lg-outer.lg-animate-thumb .lg-thumb {
  transition-timing-function: cubic-bezier(.215, .61, .355, 1);
}

/*# sourceMappingURL=index.b55b1ca8.css.map */
