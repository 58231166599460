body, article {
	background-color: black;
	box-sizing: border-box;
	padding: 0;
	margin: 0;
	font-family: "Verdana", "Arial", sans-serif;
}

a {
	color: #7f8d6e;
}

.hide {
	display: none;
}

q::before {
	content: "\2018";
}

q::after {
	content: "\2019";
}

.bg-section {
	width: 100%;
}

.bg-section .content {
	box-sizing: border-box;
	width: 100%;
	margin: 0 auto;
	padding: 0 1rem 1rem;
}

.bg-section.dark {
	background-color: black;
}
.bg-section.dark .content {
	max-width: 50rem;
	padding-bottom: 0;
}

.bg-section.light {
	background-color: white;
}
.bg-section.light .content {
	max-width: 40rem;
}

section {
	box-sizing: content-box;
	padding: 1rem 0;
}

h1 {
	margin: 3rem 1rem 1rem;
	text-align: center;
	color: white;
	font-size: 3rem;
	font-weight: 100;
}

.date {
	margin-top: -1.4em;
	font-size: 0.8rem;
}

.video {
	position: relative;
	padding: 56.25% 0 0 0;
	/* 16:9 */
	/* padding-top: 25px; */
	/* height: 0; */
}
  
.video iframe {
	box-sizing: content-box;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.secvid {
	margin-bottom: 0;
}

.buttons {
	padding: 0.5rem 0;
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
}

.button {
	width: fit-content;
	margin: 0.5em 0.4em;
	padding: 0.6em 1.6em;
	background-color: #7f8d6e;
	color: white;
	border: none;
	font-size: 1.2rem;
	text-decoration: none;
	cursor: pointer;
	transition: background-color 0.3s;
}
.button:hover, .button.active {
	background-color: #515946;
}

.collapsed {
	padding: 0;
	overflow: hidden;
}
.collapsed section {
	padding: 0;
	display: none;
    /* transition: max-height 0.25s ease-out; */
}
.collapsed section:target, .collapsed section.target {
	display: block;
}
.collapsed section.hide {
	display: none !important;
}

.links {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}
.links span + span::before {
	content: '|';
	margin: 0.7em;
}

.modal {
	margin: 2rem auto 0.5rem;
	padding: 20px;
	background-color: black;
	color: white;
	max-width: 30rem;
	/* width: 80%;  */
}

.modal form {
	display: flex;
	flex-direction: column;
}

.modal h2 {
	margin: 0 auto 0.5rem;
}

.modal p, .modal div {
	margin: 0.5rem 0;
}

.quantities {
	/* margin: 1.5rem 0; */
	display: grid;
	grid-template-columns: auto auto;
	border: none;
	padding: 0;
}
.quantities label {
	justify-self: start;
}
.quantities input {
	justify-self: end;
}
label {
	margin: 0.5rem 0 0.2rem;
	font-weight: bold;
}
input, textarea {
	margin-bottom: 0.5rem;
	border-radius: 0px;
	border: none;
}
fieldset {
	margin: 0;
}
input[type="number"] {
	width: 50px;
	height: max-content;
	font-size: 16px;
	margin: auto 0 auto 10px;
}
input[type="text"],
input[type="email"],
input[type="tel"],
textarea {
	width: 100%;
	padding: 0.3em;
	/* border: 1px solid #ccc;
	border-radius: 5px; */
	font-size: 16px;
	box-sizing: border-box;
}
input[type="text"]:focus,
input[type="email"]:focus,
input[type="tel"]:focus,
textarea:focus {
	outline: none;
	/* border-color: #008CBA; */
}
input[type="submit"] {
	/* padding: 10px; */
	font-size: 1rem;
	/* font-weight: bold; */
	margin: 20px auto 0;
    appearance: none;
}

.cf-turnstile {
	max-height: 70px;
	transition: max-height 0.25s ease-out 0.3s;
	overflow: hidden;
}

.cf-turnstile.collapse {
	max-height: 0px;
}

.gallerij {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	gap: 0.5rem;
}

@media (max-width: 30rem) {
	.gallerij {
		grid-template-columns: 1fr 1fr 1fr;
	}
}
@media (max-width: 20rem) {
	.gallerij {
		grid-template-columns: 1fr 1fr;
	}
}

.gallerij img {
	width: 100%;
	height: 100%;
	aspect-ratio: 1;
	object-fit: cover;
}

.gallerijmaxi {
	grid-template-columns: 1fr;
}

.gallerijmaxi img {
	height: initial;
	aspect-ratio: initial;
}

.vidthumb {
	position: relative; 
	cursor: pointer;
}

.vidthumb:after, .vidthumb:before {
	content: "";
	position: absolute;
	top: 50%;
	left: 50%;
}
.vidthumb:after {
	width: 0;
	height: 0;
	border: 12px solid transparent;
	border-left: 20px solid #fff;
	margin-top: -18px;
	margin-left: -7px;
}
